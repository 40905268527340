import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import axios from 'axios';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');
    const { loginUser } = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://antleverage.com/api/login', {
                email,
                password,
            });
            loginUser(response.data.user); // Store user data in context
            setStatus('Login successful!');
            navigate('/profile'); // Route to /profile after successful login
        } catch (error) {
            setStatus('Login failed. Please try again.');
        }
    };

    return (
        <Container fluid className="py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Body>
                            <h1 className="text-center mb-4">Login</h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button className='mt-3' variant="primary" size='lg' type="submit">
                                    Login
                                </Button>
                                <br />
                                <p>Dont have an account?<a href='/signup'> Signup</a></p>
                            </Form>
                            {status && (
                                <div className="mt-3 text-center">
                                    <p>{status}</p>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;
