import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

const UsersListPage = () => {
    const { user } = useUser();
    const senderidsend = user.id;
    // console.log(user.id);
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://antleverage.com/api/users');
            const usersWithLastMessage = response.data.map(user => ({
                ...user,
                lastMessageTime: user.last_message_time ? new Date(user.last_message_time) : null
            }));
            setUsers(usersWithLastMessage);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await axios.delete(`https://antleverage.com/api/users/${id}`);
                fetchUsers(); // Refresh the list after deletion
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        }
    };

    const handleDeactivate = async (id) => {
        try {
            await axios.post(`https://antleverage.com/api/users/${id}/deactivate`);
            fetchUsers(); // Refresh the list after deactivation
        } catch (error) {
            console.error('Error deactivating user:', error);
        }
    };
    

    const openModal = async (user) => {
        setSelectedUser(user);
        try {
            const response = await axios.get(`https://antleverage.com/api/chat/${user.id}`);
            setMessages(response.data);
            // console.log(response.data);            
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
        setMessages([]);
        setNewMessage('');
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        try {
            const response = await axios.post('https://antleverage.com/api/chat', {
                senderId: senderidsend,
                recipientId: selectedUser.id,
                message: newMessage
            });

            setMessages([...messages, response.data]);
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div>
            <h1>Users List</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Last Message</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.sort((a, b) => (b.lastMessageTime - a.lastMessageTime)).map(user => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{new Date(user.created_at).toLocaleDateString()}</td>
                            <td>{user.is_active ? 'Active' : 'Inactive'}</td>
                            <td>{user.lastMessageTime ? user.lastMessageTime.toLocaleString() : 'No messages'}</td>
                            <td>


                                {/* <Link to={`/users/edit/${user.id}`}><Button variant="warning">Edit</Button></Link> */}
                                <Button variant="danger" onClick={() => handleDelete(user.id)}>Delete</Button>
                                {user.is_active ? (
                                    <Button variant="secondary" onClick={() => handleDeactivate(user.id)}>Deactivate</Button>
                                ) : (
                                    <Button variant="success" onClick={() => handleDeactivate(user.id)}>Activate</Button>
                                )}
                                <Button variant="info" onClick={() => openModal(user)}>View Messages</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Chat with {selectedUser?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="chat-messages" style={{ maxHeight: '400px', overflowY: 'scroll', padding: '10px' }}>
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                className={`mb-3 ${msg.sender_id === senderidsend ? "text-right" : "text-left"}`}
                                style={{ display: 'flex', justifyContent: msg.sender_id === senderidsend ? 'flex-end' : 'flex-start' }}
                            >
                                <div style={{ maxWidth: '75%' }}>
                                    <div
                                        className={msg.sender_id === senderidsend ? 'bg-primary text-white' : 'bg-light text-dark'}
                                        style={{
                                            padding: '10px',
                                            borderRadius: '10px',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{msg.sender_id === senderidsend ? 'You' : msg.sender_name}:</strong>
                                        <p style={{ marginBottom: '5px' }}>{msg.message.trim()}</p>
                                        <small className="text-muted" style={{ fontSize: '0.8em' }}>
                                            {new Date(msg.timestamp).toLocaleString()}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message here..."
                        style={{ marginTop: '10px' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={handleSendMessage}>Send</Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default UsersListPage;
